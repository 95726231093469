import React, { lazy, useEffect, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import history from "./history";

import AccessibleNavigationAnnouncer from "./components/AccessibleNavigationAnnouncer";

import packageJson from "../package.json";
import PrivateRoute from "./privateRoute";

global.appVersion = packageJson.version;
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const CreateAccount = lazy(() => import("./pages/CreateAccount"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const SignupSuccess = lazy(() => import("./pages/SignupSuccess"));

const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

function App() {
  useEffect(() => {
    fetch("/meta.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch meta.json");
        }
        return response.json();
      })
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`
          );

          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
          });
          window.location.reload();
          // setLoading(false);
          // setLatestVersion(false);
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );
          // setLoading(false);
          // setLatestVersion(true);
        }
      })
      .catch((error) => {
        console.error("Error fetching meta.json:", error);
      });
  }, []);

  return (
    <>
      <Router history={history}>
        <AccessibleNavigationAnnouncer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/create-account" component={CreateAccount} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/signup-success" component={SignupSuccess} />

          {/* Place new routes over this */}
          {/* <Route path="/app" component={Layout} /> */}
          {/* If you have an index page, you can remothis Redirect */}
          <Redirect exact from="/" to="/login" />

          <PrivateRoute path="/" component={Layout} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
