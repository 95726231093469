export default {
  button: {
    primary: {
      base: "text-white bg-blue-500 border border-transparent",
      active:
        "active:bg-white-600 hover:bg-blue-600 focus:ring focus:ring-blue-300",
      disabled: "opacity-50 cursor-not-allowed",
    },
  },
  input: {
    checkbox:
      "text-blue-600 form-checkbox focus:border-blue-400 focus:outline-none focus:shadow-outline-blue dark:focus:shadow-outline-gray",
  },
};
