export const HASURA_ENDPOINT_DEV = "hasura.hyperclapper.click/v1/graphql";
export const BASE_ENDPOINT =
  "https://us-central1-hyperclapper.cloudfunctions.net";

export const OPENAI_CHAT_ENDPOINT =
  "https://api.openai.com/v1/chat/completions";

export const OPENAI_API_KEY =
  "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";

// export const BASE_ENDPOINT = "http://localhost:5001/hyperclapper/us-central1";

export const firebaseConfig = {
  apiKey: "AIzaSyBWfkVMXoJswG_8SGTX-F8Qwbrj8Fp13P0",
  authDomain: "hyperclapper.firebaseapp.com",
  projectId: "hyperclapper",
  storageBucket: "hyperclapper.appspot.com",
  messagingSenderId: "711915071503",
  appId: "1:711915071503:web:fd350eb83ade180a6c9b7a",
  measurementId: "G-0WEZSTNB4P",
};
